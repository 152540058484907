import { connect } from 'react-redux';
import { authorize } from 'componentlibrary'
import AuthModal from '../../Components/AuthModal';


const mapStateToProps = (state, ownProps) => ({
  showAuthConfirm: state.auth.showAuthConfirm,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  authorize(...arg) {
    return authorize(dispatch, ...arg);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
