import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Button, Spin, Icon } from 'antd';
import { MaintenanceMode, Exception } from 'componentlibrary';
import ResponsiveLayout from '../../Containers/ResponsiveLayout';
import AuthModal from '../../Containers/AuthModal';
import { routes } from '../../routes';
import { loader } from '../../sass/modules/loader.module.scss';
import styles from './style.module.scss';
import Config from '../../Config';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false
    };
  }

  componentDidMount() {
    const { appConfig } = this.props;
    if (appConfig && appConfig.maintenanceMode === false) {
      this.handleAuth();
    }
    this.getAppConfig();
  }

  componentDidUpdate(prevProps) {
    const { auth, appConfig } = this.props;
    const { showError } = this.state;

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if ((prevProps.appConfig
      && prevProps.appConfig.maintenanceMode === true
      && appConfig.maintenanceMode === false)
      || (prevProps.appConfig === null && appConfig && appConfig.maintenanceMode === false)) {
      this.handleAuth();
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  componentWillUnmount() {
    const { wsDisconnect } = this.props;
    wsDisconnect();
  }

  async handleAuth() {
    const { authInit, appConfig } = this.props;
    // const code = this.getSearchParam('code');
    // const state = this.getSearchParam('state');

    // // We are not currently going through the authorization flow.
    // // Call handleAuthSession to use an existing session or begin a new one.
    // if (!code) {
    //   return handleAuthSession();
    // }

    // // Auth code flow in progress. Invoke token exchange
    // await exchangeCodeForTokens(code, state);
    // return this.redirect();

    // NEW CODE
    const { appClientId, userPoolId, reactAppCookieDomain } = appConfig;
    const forwoodIdUrl = `${Config.forwoodId.URL}?redirect_uri=${Config.reactApp.HOSTNAME}`;
    const payload = {
      appClientId, userPoolId, cookieStorage: reactAppCookieDomain, forwoodIdUrl,
    };
    authInit(payload);
  }

  handleAuthError() {
    this.setState({
      showError: true
    });
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return 'redirecting';
    }

    if (this.getSearchParam('code') !== null) {
      return 'authorizing';
    }

    return null;
  }

  async getAppConfig() {
    const { getAppConfig, wsConnect, configServiceFetchRequest } = this.props;
    await getAppConfig();
    configServiceFetchRequest(Config.configurationService.URL);
    wsConnect(Config.configurationService.WEBSOCKET);
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem('lastPath') || '/';
    sessionStorage.removeItem('lastPath');

    return history.push(pathname);
  }

  render() {
    const { showError } = this.state;
    const {
      t, appConfig, auth = {}, i18n
    } = this.props;

    if (!appConfig) {
      return null;
    }

    const { error, accessToken } = auth;

    const currentURL = window.origin;

    const menuItem = [
      {
        container: 'CompanyMicroFrontend',
        icon: <Icon type="apartment" className={styles.icon} />,
        title: t('companies'),
        path: '/company',
      },
      {
        container: 'PhysicalLocationMicroFrontend',
        icon: <Icon type="pushpin" className={styles.icon} />,
        title: t('physicalLocations'),
        path: '/physical-location',
      }
    ];

    if (this.loaderMessage) {
      return (
        <Spin
          className={loader}
          size="large"
          tip={t(this.loaderMessage)}
        >
          <div />
        </Spin>
      );
    }

    if (error && showError) {
      return (
        <Exception
          status={error.status}
          message={t(`errors:${error.status.toString()}`)}
        >
          <Button onClick={null}>
            {t('errors:tryAgain')}
          </Button>
        </Exception>
      );
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:systemDown')}
          description={t('maintenanceMode:pleaseLogout')}
        />
      );
    }

    if (!accessToken) {
      return (
        <AuthModal />
      );
    }
    return (
      <ResponsiveLayout
        menuProps={menuItem}
        i18n={i18n}
        currentUrl={currentURL}
        supportMenuEnabled={appConfig.supportMenuEnabled}
      >
        {renderRoutes(routes)}
        <AuthModal />
      </ResponsiveLayout>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null
};

App.propTypes = {
  auth: PropTypes.object,
  appConfig: PropTypes.object,
  getAppConfig: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  wsConnect: PropTypes.func.isRequired,
  configServiceFetchRequest: PropTypes.func,
  wsDisconnect: PropTypes.func.isRequired,
  authInit: PropTypes.func.isRequired,

};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
