import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { createApiMiddleware, wsMiddleware, authDefaultState, authMiddleware } from 'componentlibrary';
import rootReducer from '../reducers';
import { appConfigDefaultState } from '../reducers/appConfig';
import { microFrontendDefaultState } from '../reducers/microFrontend';
import Config from '../Config';


export const initialStore = {
  appConfig: appConfigDefaultState(),
  auth: authDefaultState(),
  microFrontend: microFrontendDefaultState(),
  local_config: {...Config}
};

const middleware = [thunk, createApiMiddleware, wsMiddleware, authMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

middleware.forEach((mw, index) => {
  if (typeof mw !== 'function') {
    middleware.splice(index, 1);
  }
});

export default createStore(
  rootReducer,
  initialStore,
  applyMiddleware(...middleware)
);
